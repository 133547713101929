<template>
  <div class="card">
    <div class="card-header">
      <h1 class="page__title card__title">{{ title }}</h1>
      <slot name="action"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: { title: { type: String, required: true } }
}
</script>

<style scoped>
.card {
  width: 100%;
  text-align: left;
  padding: 25px;
  margin-bottom: 2rem;
}

.card__title {
  font-size: 20px;
  font-weight: bold;
  margin-left: 0.5rem;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}
</style>
